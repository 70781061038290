import React from 'react'
import './experience.css'
import {BsPatchCheckFill} from 'react-icons/bs'

function experience() {
  return (
    <section id='experience'>
      <h5>What Skills do I have</h5>
      <h2>My Experience</h2>

      <div className="container experience__container">
        <div className="experience_frontend">
          <h3>Frontend Development</h3>
          <div className="experience_content">
            <article className="experience_details">
              <BsPatchCheckFill className="experience__details-icon"/> 
              <div>
              <h4>HTML</h4>
              <small className='text-light'>Experienced</small>   
              </div>           
            </article>
            <article className="experience_details">
              <BsPatchCheckFill className="experience__details-icon"/> 
              <div>
              <h4>CSS</h4>
              <small className='text-light'>Intermediate</small>
              </div>
            </article>
            <article className="experience_details">
              <BsPatchCheckFill className="experience__details-icon"/> 
              <div>
              <h4>Javascript</h4>
              <small className='text-light'>Beginner</small>
              </div>
            </article>
            <article className="experience_details">
              <BsPatchCheckFill className="experience__details-icon"/> 
              <div>
              <h4>Bootstrap</h4>
              <small className='text-light'>Beginner</small>
              </div>
            </article>
            <article className="experience_details">
              <BsPatchCheckFill className="experience__details-icon"/> 
              <div>
              <h4>Tailwind</h4>
              <small className='text-light'>Intermediate</small>
              </div>
            </article>
            <article className="experience_details">
              <BsPatchCheckFill className="experience__details-icon"/> 
              <div>
              <h4>React</h4>
              <small className='text-light'>Intermediate</small>
              </div>
            </article>
          </div>

        </div>
        <div className="experience_backend">
        <h3>Backend Development</h3>
          <div className="experience_content">
            <article className="experience_details">
              <BsPatchCheckFill className="experience__details-icon"/>
              <div>
              <h4>NodeJs</h4>
              <small className='text-light'>Beginner</small>
              </div>              
            </article>
            <article className="experience_details">
              <BsPatchCheckFill className="experience__details-icon"/> 
              <div>
              <h4>MongoDB</h4>
              <small className='text-light'>Beginner</small>
              </div>
            </article>
            <article className="experience_details">
              <BsPatchCheckFill className="experience__details-icon"/> 
              <div>
              <h4>PHP</h4>
              <small className='text-light'>Beginner</small>
              </div>
            </article>
            <article className="experience_details">
              <BsPatchCheckFill className="experience__details-icon"/> 
              <div>
              <h4>Pyhton</h4>
              <small className='text-light'>Intermediate</small>
              </div>              
            </article>
           
          </div>
        </div>
      </div>  
    </section>
  )
}

export default experience